import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "light--text ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_inbound_calls_dropdown = _resolveComponent("inbound-calls-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": _ctx.itemsName,
    "show-selection": _ctx.showSelection,
    class: "contacts-table"
  }, {
    "body-cell-type-slot": _withCtx((props) => [
      (props.row.type === 'accepted')
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 0,
            name: "phone_callback",
            class: "success--text"
          }))
        : (_openBlock(), _createBlock(_component_tm_icon, {
            key: 1,
            name: "phone_missed",
            class: "error--text"
          }))
    ]),
    "body-cell-from-slot": _withCtx((props) => [
      _createVNode(_component_router_link, {
        to: {
          name: 'base.history.inboundCalls.details',
          params: { id: props.row.id },
        }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_person, {
            name: props.row.from.name || props.row.from.phone,
            "avatar-color": props.row.from.avatarColor,
            "avatar-size": "small"
          }, null, 8, ["name", "avatar-color"])
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-to-slot": _withCtx((props) => [
      _createVNode(_component_router_link, {
        class: "d-flex align-center font-color-inherit font-weight-normal blue-on-hover",
        to: {
          name: 'base.history.inboundCalls.details',
          params: { id: props.row.id },
        }
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.row.to.phone) + " ", 1),
          _createElementVNode("span", _hoisted_1, " (" + _toDisplayString(props.row.to.team) + ") ", 1)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-actions": _withCtx((props) => [
      _createVNode(_component_inbound_calls_dropdown, {
        "item-id": props.row.id
      }, null, 8, ["item-id"])
    ]),
    _: 1
  }, 8, ["headers", "items", "items-name", "show-selection"]))
}