
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import InboundCallsDropdown from '@/components/pages/history/inboundCalls/InboundCallsDropdown.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import type { TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmPerson,
    TmTable,
    InboundCallsDropdown,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemsName: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
  },
})
